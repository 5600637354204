// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diagnozy-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/diagnozy.js" /* webpackChunkName: "component---src-pages-diagnozy-js" */),
  "component---src-pages-index-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inne-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/inne.js" /* webpackChunkName: "component---src-pages-inne-js" */),
  "component---src-pages-namiot-profilaktyczny-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/namiot-profilaktyczny.js" /* webpackChunkName: "component---src-pages-namiot-profilaktyczny-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-programy-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/programy.js" /* webpackChunkName: "component---src-pages-programy-js" */),
  "component---src-pages-referencje-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/referencje.js" /* webpackChunkName: "component---src-pages-referencje-js" */),
  "component---src-pages-regulamin-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-strategie-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/strategie.js" /* webpackChunkName: "component---src-pages-strategie-js" */),
  "component---src-pages-szkolenia-wyjazdowe-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/szkolenia-wyjazdowe.js" /* webpackChunkName: "component---src-pages-szkolenia-wyjazdowe-js" */),
  "component---src-pages-szkolenia-dla-osob-indywidualnych-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/szkolenia/dla-osob-indywidualnych.js" /* webpackChunkName: "component---src-pages-szkolenia-dla-osob-indywidualnych-js" */),
  "component---src-pages-szkolenia-dla-specjalistow-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/szkolenia/dla-specjalistow.js" /* webpackChunkName: "component---src-pages-szkolenia-dla-specjalistow-js" */),
  "component---src-pages-szkolenia-index-js": () => import("/Users/bartlomiejporadzisz/Documents/dev.nosync/sbc/src/pages/szkolenia/index.js" /* webpackChunkName: "component---src-pages-szkolenia-index-js" */)
}

